import { Bar, mixins } from "vue-chartjs";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  extends: Bar,
  mixins: [mixins.reactiveProp]
})
export default class BarChart extends Vue {
  @Prop() readonly dataset!: Array<any>;
  @Prop() readonly options!: Record<string, any>;
  renderChart: any;
  mounted() {
    this.renderChart(this.dataset, this.options);
  }
}
