var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v("گزارشات کلی")]),_c('period',{attrs:{"default-value":_vm.selectedPeriod},on:{"change-value":function($event){_vm.selectedPeriod = $event}}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("تعداد تماسهای ثبت شده")]),_c('div',{staticStyle:{"width":"100%"}},[_c('bar-chart',{attrs:{"styles":{ height: '300px', position: 'relative' },"options":_vm.options,"chart-data":_vm.mainUser}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("تعداد تماس های ثبت شده به تفکیک هر منتور")]),_vm._l((_vm.callMentors),function(item){return _c('v-list-item',{key:item.mentor_id,attrs:{"aria-rowcount":"2"}},[_c('v-list-item-avatar',[_c('img',{attrs:{"src":_vm._f("full_avatar")((item.mentor ? item.mentor.avatar : null)),"alt":""}})]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
                    (item.mentor ? item.mentor.fname : null) +
                    ' ' +
                    (item.mentor ? item.mentor.lname : null)
                  )}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.val + ' رویداد ثبت شده')}})],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","href":'/events?mentor_id=' + item.user.id}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-information")])],1)]}}],null,true)},[_c('span',[_vm._v("مشاهده رویدادها")])])],1)],1)})],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }