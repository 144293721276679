


































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { VSelect, VBtn } from "vuetify/lib";
import Enum from "@/config/enum";

@Component({
  components: {
    VSelect,
    VBtn
  }
})
export default class Period extends Vue {
  @Prop({ default: "-30 days" })
  defaultValue = "-30 days";
  private period = Enum.period;
  private selectedPeriod = this.defaultValue;

  @Watch("selectedPeriod")
  public onPageChanged() {
    this.$emit("change-value", this.selectedPeriod);
  }
}
