import Api from "./api";
import { AxiosResponse } from "axios";

export class ReportApi extends Api {
  private token = "";

  constructor() {
    super();
    this.token = localStorage.getItem("token") || "";
  }

  public mainEvent(data: Record<string, any>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("report/event", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params: data
    });
  }

  public mainUsers(data: Record<string, any>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("report/user", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params: data
    });
  }

  public mainCall(data: Record<string, any>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("report/call", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params: data
    });
  }

  public mainOffice(data: Record<string, any>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("report/office", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params: data
    });
  }

  public mainOfficeCall(data: Record<string, any>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("report/office/call", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params: data
    });
  }

  public bests(data: Record<string, any>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("report/bests", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params: data
    });
  }

  public callMentors(data: Record<string, any>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("report/call-mentor", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params: data
    });
  }
  public weekViews(data: Record<string, any>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("/report/week", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params: data
    });
  }
}
