













































































import { Component, Vue, Watch } from "vue-property-decorator";
import store from "../../../store";
import { AxiosResponse } from "axios";
import Enum from "@/config/enum";
import { ReportApi } from "@/networks/ReportApi";
import BarChart from "@/views/components/charts/BarChart";
import Period from "@/views/components/inputs/period.vue";
import moment from "moment-jalaali";

@Component({
  components: {
    BarChart: BarChart,
    period: Period
  }
})
export default class General extends Vue {
  private selectedPeriod = "-30 days";
  private options = Enum.ChartOptions;

  private mainUser = {
    labels: [],
    datasets: []
  };

  private callMentors = {};

  mounted() {
    this.all();
  }

  public all(): void {
    this.users();
    this.calls();
  }


  public async calls(): Promise<void> {
    try {
      const res: AxiosResponse = await new ReportApi().callMentors({
        period: this.selectedPeriod
      });

      this.callMentors = res.data.data;
      console.log(this.callMentors);
    } catch (error: any) {
      console.log(error);
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  public getRandomColor(): string {
    const letters = "0123456789ABCDEF".split("");
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  public async users(): Promise<void> {
    try {
      const res: AxiosResponse = await new ReportApi().mainUsers({
        period: this.selectedPeriod
      });
      this.mainUser = {
        labels: res.data.data.dates.map((x: string) =>
          moment(x).format("jYYYY/jMM/jDD")
        ),
        datasets: [
          {
            label: "تعداد کاربران ثبت شده روزانه",
            backgroundColor: this.$vuetify.theme.currentTheme.primary,
            data: res.data.data.vals
          }
        ] as any
      };
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  @Watch("selectedPeriod")
  public onSelectedPeriodChange(val: string, oldVal: string): void {
    if (val != oldVal) {
      this.all();
    }
  }
}
