export default class Enum {
  public static period = [
    {
      name: "امروز",
      slug: "-1 days"
    },
    {
      name: "3 روز اخیر",
      slug: "-3 days"
    },
    {
      name: "7 روز اخیر",
      slug: "-7 days"
    },
    {
      name: "2 هفته اخیر",
      slug: "-14 days"
    },
    {
      name: "1 ماه اخیر",
      slug: "-30 days"
    },
    {
      name: "3 ماه اخیر",
      slug: "-3 months"
    },
    {
      name: "6 ماه اخیر",
      slug: "-6 months"
    },
    {
      name: "1 سال اخیر",
      slug: "-12 month"
    }
    // {
    //   name: "کل",
    //   slug: ""
    // }
  ];
  public static eventTypes = [
    {
      text: "همه",
      value: ""
    },
    {
      text: "ثبت تماس",
      value: "init"
    },
    {
      text: "دایرکت اینستاگرام",
      value: "instagram"
    },
    {
      text: "Crisp",
      value: "crisp"
    },
    {
      text: "بررسی توسط کاشناس",
      value: "review"
    },
    {
      text: "مشاوره آنلاین",
      value: "phone"
    },
    {
      text: "مشاوره حضوری",
      value: "here"
    },
    {
      text: "پرداخت مبلغ",
      value: "pay"
    },
    {
      text: "قرارداد",
      value: "contract"
    },
    {
      text: "درخواست کنسلی",
      value: "cancel"
    },
    {
      text: "پذیرش نهایی",
      value: "accept"
    },
    {
      text: "اتمام قرارداد",
      value: "finish"
    }
  ];
  public static eventStatusAdmin = [
    {
      text: "وقت مشاوره",
      value: 0
    },
    {
      text: "مشاوره شد",
      value: 1
    },
    {
      text: "کنسل شد",
      value: 2
    },
    {
      text: "قدیمی",
      value: 4
    },
    {
      text: "در انتظار مشاوره",
      value: 5
    }
  ];
  public static eventStatus = [
    {
      text: "درانتظار تعیین وضعیت",
      value: 0
    },
    {
      text: "انجام شد",
      value: 1
    },
    {
      text: "کنسل شد",
      value: 2
    },
    {
      text: "از تاریخ رویداد گذشته (منقضی شده)",
      value: 4
    },
    {
      text: "در انتظار جلسه",
      value: 5
    }
  ];
  public static onlineTypes = [
    "phone",
    "expert-doctor-online",
    "managers-online"
  ];
  public static eventTypesQuick = [
    {
      text: "مشاوره تلفنی (اسکایپی، اینترنتی و ...)",
      tooltip: "هزینه 300 هزار تومان",
      value: "phone"
    },
    {
      text: "مشاوره  VIP (فوری)",
      tooltip: "هزینه 2500 هزار تومان",
      value: "vip"
    },
    {
      text: "مشاوره حضوری",
      tooltip: "هزینه 500 هزار تومان",
      value: "here"
    },
    {
      text: "مشاوره تخصص",
      tooltip:
        "پزشکی٬ دندان٬ سرمایه گذاری٬ ثبت شرکت٬اخذ اقامت - 1 میلیون تومان",
      value: "expert-doctor"
    },
    // {
    //   text: "مشاوره از مدیران مجموعه",
    //   value: "managers"
    // },
    {
      text: "مشاوره تخصص (آنلاین)",
      tooltip:
        "پزشکی٬ دندان٬ سرمایه گذاری٬ ثبت شرکت٬اخذ اقامت - 1 میلیون تومان",
      value: "expert-doctor-online"
    }
    // {
    //   text: "مشاوره از مدیران مجموعه(آنلاین)",
    //   value: "managers-online"
    // }
  ];
  public static eventTypesQuickWithPrice = [
    {
      text: "مشاوره  VIP (فوری) - 2500 هزار تومان",
      value: "vip"
    },
    {
      text: "مشاوره حضوری - 500 هزار تومان",
      value: "here"
    },
    {
      text:
        "مشاوره تخصص (پزشکی٬ دندان٬ سرمایه گذاری٬ ثبت شرکت٬اخذ اقامت) حضوری - 1 میلیون تومان",
      value: "expert-doctor"
    },
    {
      text: "مشاوره از مدیران مجموعه حضوری - 500 دلار",
      value: "managers"
    },
    {
      text: "مشاوره عادی غیرحضوری - 300 هزار تومان",
      value: "phone"
    },
    {
      text:
        "مشاوره تخصص (پزشکی٬ دندان٬ سرمایه گذاری٬ ثبت شرکت٬اخذ اقامت) آنلاین - 1 میلیون تومان",
      value: "expert-doctor-online"
    },
    {
      text: "مشاوره از مدیران مجموعه غیرحضوری - 500 دلار",
      value: "managers-online"
    }
  ];
  public static userSource = [
    {
      text: "ثبت تماس",
      value: "call"
    },
    {
      text: "دایرکت اینستاگرام",
      value: "instagram"
    },
    {
      text: "Crisp",
      value: "crisp"
    },
    {
      text: "Twitter",
      value: "twitter"
    },
    {
      text: "Telegram",
      value: "telegram"
    }
  ];
  public static months = [
    {
      text: "فروردین",
      value: "1"
    },
    {
      text: "اردیبهشت",
      value: "2"
    },
    {
      text: "خرداد",
      value: "3"
    },
    {
      text: "تیر",
      value: "4"
    },
    {
      text: "مرداد",
      value: "5"
    },
    {
      text: "شهریور",
      value: "6"
    },
    {
      text: "مهر",
      value: "7"
    },
    {
      text: "آبان",
      value: "8"
    },
    {
      text: "آذر",
      value: "9"
    },
    {
      text: "دی",
      value: "10"
    },
    {
      text: "بهمن",
      value: "11"
    },
    {
      text: "اسفند",
      value: "12"
    }
  ];
  public static userConnectSources = [
    "crisp",
    "instagram",
    "telegram",
    "twitter",
    "call"
  ];
  public static count = [
    {
      name: "5",
      slug: 5
    },
    {
      name: "10",
      slug: 10
    },
    {
      name: "20",
      slug: 20
    },
    {
      name: "50",
      slug: 50
    },
    {
      name: "100",
      slug: 100
    }
  ];
  public static currencies = [
    {
      text: "تومان ایران",
      value: "IRR"
    },
    {
      text: "دلار آمریکا",
      value: "USD"
    },
    {
      text: "یورو",
      value: "EUR"
    },
    {
      text: "پوند انگلیس",
      value: "GBP"
    },
    {
      text: "دیگر ارزها - در خلاصه نوشته شود",
      value: "other"
    }
  ];
  public static ChartOptions = {
    responsive: true,
    maintainAspectRatio: false
  };
  public static TypePayment = [
    {
      text: "هیچکدام",
      value: null
    },
    {
      text: "آنلاین",
      value: 1
    },
    {
      text: "نقدی",
      value: 2
    },
    {
      text: "پوز بانک دی",
      value: 3
    },
    {
      text: "پوز شرکت",
      value: 4
    },
    {
      text: "پوز بانک ملی",
      value: 5
    },
    {
      text: "کارت به کارت",
      value: 6
    },
    {
      text: "دیگر",
      value: 9
    }
  ];
  public static eventPaymentType = {
    "0": "پرداخت آنلاین",
    "1": "پرداخت کارت به کارت",
    "2": "پرداخت نقدی یا پوز"
  };
  public static MigrationMethods = [
    {
      text: "تحصیلی",
      value: 1
    },
    {
      text: "کاری",
      value: 2
    },
    {
      text: "سرمایه گذاری",
      value: 3
    },
    {
      text: "سرچ",
      value: 4
    },
    {
      text: "دیگر",
      value: 9
    }
  ];
  public static all_money_i_have = [
    {
      text: " کمتر از 200 میلیون تومان",
      value: 7
    },
    {
      text: "200 تا 500 میلیون تومان",
      value: 6
    },

    {
      text: "500 میلیون تا 1 میلیارد تومان",
      value: 5
    },
    {
      text: "1 تا 2 میلیارد تومان",
      value: 4
    },
    {
      text: "2 تا 5 میلیارد تومان",
      value: 3
    },
    {
      text: "5 تا 10 میلیارد تومان",
      value: 2
    },
    {
      text: "بیش از 10 میلیارد تومان",
      value: 1
    }
  ];
  public static monthly_salary = [
    {
      text: " کمتر از 5 میلیون تومان",
      value: 7
    },
    {
      text: "5 تا 10 میلیون تومان",
      value: 6
    },
    {
      text: "10 تا 15 میلیون تومان",
      value: 5
    },
    {
      text: "15 تا 25 میلیون تومان",
      value: 4
    },
    {
      text: "25 تا 50 میلیون تومان",
      value: 3
    },
    {
      text: "50 تا 100 میلیون تومان",
      value: 2
    },
    {
      text: "بیش از 100 میلیون تومان",
      value: 1
    }
  ];
  public static military_status = [
    {
      text: "گذرانده",
      value: 1
    },
    {
      text: "معافیت تحصیلی",
      value: 2
    },
    {
      text: "مشمول",
      value: 3
    },
    {
      text: "دیگر",
      value: 9
    }
  ];
  public static type_of_fund = [
    {
      text: "ماشین",
      value: 1
    },
    {
      text: "ملک",
      value: 2
    },
    {
      text: "دارایی نقدی",
      value: 3
    },
    {
      text: "سهام",
      value: 4
    },
    {
      text: "دیگر",
      value: 9
    }
  ];
  public static grades = [
    {
      text: "دیپلم",
      value: "1"
    },
    {
      text: "لیسانس",
      value: "2"
    },
    {
      text: "فوق لیسانس",
      value: "6"
    },
    {
      text: "دکتری",
      value: "3"
    },
    {
      text: "پست دکتری",
      value: "4"
    },
    {
      text: "فنی حرفه ای",
      value: "5"
    },
    {
      text: "دیگر",
      value: "9"
    }
  ];
  public static languages = [
    {
      text: "انگلیسی",
      value: 1
    },
    {
      text: "فرانسوی",
      value: 2
    },
    {
      text: "آلمانی",
      value: 3
    },
    {
      text: "ایتالیایی",
      value: 4
    },
    {
      text: "اسپانیایی",
      value: 5
    },
    {
      text: "ترکی",
      value: 6
    },
    {
      text: "روسی",
      value: 7
    },
    {
      text: "عربی",
      value: 8
    },
    {
      text: "چینی",
      value: 10
    },
    {
      text: "کره ای",
      value: 11
    },
    {
      text: "ژاپنی",
      value: 12
    },
    {
      text: "کردی",
      value: 13
    },
    {
      text: "دیگر",
      value: 9
    }
  ];
  public static language_level = [
    {
      text: "A1 - شروع",
      value: "a1"
    },
    {
      text: "A2 - ابتدایی",
      value: "a2"
    },
    {
      text: "B1 - متوسط به پایین",
      value: "b1"
    },
    {
      text: "B2 - متوسط",
      value: "b2"
    },
    {
      text: "C1 - متوسط به بالا",
      value: "c1"
    },
    {
      text: "C2 - پیشرفته",
      value: "c2"
    }
  ];
}
